import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, Container, Box, Typography, TextField, Button, Snackbar } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App = () => {
  const [email, setEmail] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        const response = await fetch('https://kastalia.org/submit_email.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });
        const data = await response.json();
        setSnackbarMessage(data.message);
        setSnackbarOpen(true);
        if (data.success) {
          setEmail('');
        }
      } catch (error) {
        setSnackbarMessage('An error occurred. Please try again.');
        setSnackbarOpen(true);
      }
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundImage: 'url(/kastalia.webp), url(/kastalia.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Box
          sx={{
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 3,
          }}
        >
          <Container maxWidth="sm">
            <picture>
              <source srcSet="/load.webp" type="image/webp" />
              <img 
                src="/load.png" 
                alt="Kastalia Logo" 
                style={{ width: 150, height: 150, marginBottom: 16, display: 'block', margin: '0 auto' }} 
              />
            </picture>
            <Typography variant="h2" align="center" gutterBottom>
              Kastalia
            </Typography>
            <Typography variant="h6" align="center" paragraph>
              Interactive, textbook-type education apps designed for university-level studies. Our apps are coming soon!
            </Typography>
            <Typography variant="body1" align="center" paragraph>
              Sign up with your email to stay updated:
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
            </Box>
            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              Kastalia comes from The Glass Bead Game, referencing someone who once devoted his life to academia, but found the need to engage with the real world.
            </Typography>
          </Container>
        </Box>
        <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', padding: 1 }}>
          <Typography variant="body2" align="center">
            © 2023 Kastalia. All rights reserved.
          </Typography>
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </ThemeProvider>
  );
};

export default App;